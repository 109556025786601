var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-header page-header-small clear-filter",attrs:{"filter-color":"orange"}},[_c('parallax',{staticClass:"page-header-image",staticStyle:{"background-image":"url('img/cbg3.jpg')"}}),_vm._m(0)],1),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container resume-page"},[_c('div',{staticClass:"row resume-container"},[_vm._m(1),_c('div',{staticClass:"col-md-12 resume-section"},[_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-md-12"},[_vm._m(5),_c('h6',{staticClass:"text-muted"},[_vm._v("Web Developer")]),_c('p',{staticClass:"description"},[_vm._v("As a web developer, I was responsible for the daily maintenance and features of the custom .NET e-commerce web application.")]),_c('p',{staticClass:"description"},[_vm._v("The teams we built turned around a decimated department, and helped move the company into the "),_c('quote',[_vm._v("\"Web 2.0 era\"")]),_vm._v(".")],1),_c('p',{staticClass:"description"},[_vm._v("We managed our own email server! It was around for so many years that it had a pretty solid reputation score. One cool project was creating an email templating service provider internal to the company.")])]),_vm._m(6)])])])]),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-center"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v(" Resumé of Chris Missal"),_c('br'),_c('small',[_vm._v("Full-stack Software developer")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 resume-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 text-right"},[_c('h3',{staticClass:"title title-up"},[_vm._v("Personal Info")])]),_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"description"},[_c('br'),_vm._v("Intentionally left blank, available upon request soon.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 text-right"},[_c('h3',{staticClass:"title title-up"},[_vm._v("Experiences & Responsibilities")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('h4',{staticClass:"category"},[_vm._v("Workify "),_c('small',{staticClass:"text-muted"},[_vm._v("Austin, Texas")]),_vm._v(" "),_c('small',{staticClass:"text-secondary"},[_vm._v("2015 – present")])]),_c('h6',{staticClass:"text-muted"},[_vm._v("Engineering Team Lead")]),_c('p',{staticClass:"description"},[_vm._v("At Workify we're building an employee surveying and feedback platform. Our company's \"be human\" core value allows our product team to design design a product that we would use ourselves. No sneaky, unethical selling of data, or giving it to advertisers!")]),_c('p',{staticClass:"description"},[_vm._v("I'm super proud of our entire team, past and present, and look forward to the next chapter we're writing!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('h4',{staticClass:"category"},[_vm._v("Headspring "),_c('small',{staticClass:"text-muted"},[_vm._v("Austin, Texas")]),_vm._v(" "),_c('small',{staticClass:"text-secondary"},[_vm._v("2012 – 2015")])]),_c('h6',{staticClass:"text-muted"},[_vm._v("Senior Consultant")]),_c('p',{staticClass:"description"},[_vm._v("My time at Headspring, a custom software solutions company, accelerated my growth in greenfield projects, legacy technologies, as well as many industries and institutions.")]),_c('p',{staticClass:"description"},[_vm._v("I expanded my toolset while I learned from many, and taught others. We also built some cool open source tools.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"category"},[_vm._v("J&P Cycles "),_c('small',{staticClass:"text-muted"},[_vm._v("Eastern Iowa")]),_vm._v(" "),_c('small',{staticClass:"text-secondary"},[_vm._v("2007 – 2012")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('h4',{staticClass:"category"},[_vm._v("Iowa Code Camp "),_c('small',{staticClass:"text-secondary"},[_vm._v("2009 – 2011")])]),_c('h6',{staticClass:"text-muted"},[_vm._v("Organizer and Presenter")]),_c('p',{staticClass:"description"},[_vm._v("This is or was the largest free software conference in Iowa "),_c('small',[_vm._v("[citation needed]")]),_vm._v(".")]),_c('p',{staticClass:"description"},[_vm._v("My typical role was to help with selecting and scheduling speakers, and obtaining sponsorships.")]),_c('p',{staticClass:"description"},[_vm._v("In addition to helping with volunteer duties, I presented several sessions over the years.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 resume-section"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 resume-section cm-languages cm-self-ratings"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 text-right"},[_c('h3',{staticClass:"title title-up"},[_vm._v("Languages")]),_c('p',[_vm._v("Some of my more comfortable languages and my self assigned productivity score")])]),_c('div',{staticClass:"col-md-6"},[_c('ul',[_c('li',[_vm._v("C# "),_c('em',[_vm._v("(9/10)")])]),_c('li',[_vm._v("SQL "),_c('em',[_vm._v("(8/10)")])]),_c('li',[_vm._v("JavaScript "),_c('em',[_vm._v("(7/10)")])]),_c('li',[_vm._v("Java "),_c('em',[_vm._v("(6/10)")])]),_c('li',[_vm._v("python "),_c('em',[_vm._v("(3/10)")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 resume-section cm-databases cm-self-ratings"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 text-right"},[_c('h3',{staticClass:"title title-up"},[_vm._v("Databases")]),_c('p',[_vm._v("My level of comfort developing on databases and other storage solutions")])]),_c('div',{staticClass:"col-md-6"},[_c('ul',[_c('li',[_vm._v("SQL Server "),_c('em',[_vm._v("(9/10)")])]),_c('li',[_vm._v("Graph DBs "),_c('em',[_vm._v("(6/10)")])]),_c('li',[_vm._v("Key-value stores "),_c('em',[_vm._v("(6/10)")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 resume-section cm-tools cm-self-ratings"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 text-right"},[_c('h3',{staticClass:"title title-up"},[_vm._v("Tools")]),_c('p',[_vm._v("A list of some tools that I've come to appreciate over time.")])]),_c('div',{staticClass:"col-md-6"},[_c('ul',[_c('li',[_c('span',{staticClass:"item"},[_vm._v("Microservices")]),_vm._v(", "),_c('span',{staticClass:"time-ago-text"},[_vm._v("since 2015")])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("SPAs")]),_vm._v(", "),_c('span',{staticClass:"time-ago-text"},[_vm._v("since 2014")])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("Message brokers")]),_vm._v(", "),_c('span',{staticClass:"time-ago-text"},[_vm._v("since 2013")])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("git")]),_vm._v(", "),_c('span',{staticClass:"time-ago-text"},[_vm._v("since 2009")])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("Linux")]),_vm._v(", "),_c('span',{staticClass:"time-ago-text"},[_vm._v("since 2018")])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("RDBMS")]),_vm._v(", "),_c('span',{staticClass:"time-ago-text"},[_vm._v("since 2003")])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("Windows")]),_vm._v(", "),_c('span',{staticClass:"time-ago-text"},[_vm._v("since forever?")])])])])])])
}]

export { render, staticRenderFns }